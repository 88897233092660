import React, { useEffect, useState } from 'react';
import { StaticGoogleMap, Marker } from 'react-static-google-map';
import { Flex, Text, Box, Image } from 'rebass/styled-components';
import Pagination from '@material-ui/lab/Pagination';
import { Link } from 'gatsby';

import MainInfo from '../../molecules/SearchPage/MainInfo/MainInfo';
import Loader from '../../molecules/ListingPage/Loader/Loader';
import ListingSvc from '../../../services/listingSvc';
import Placeholder from '../../../assets/fill-52.svg';
import Map from '../../molecules/SearchPage/Map/Map';
import Banner from '../../atoms/Banner/banner';

// Minor Change to test Jenkins Deploy

const SearchPage = ({ search }) => {
  const [resultList, setResultList] = useState([]);
  const [total, setTotal] = useState(1);
  const [maxListings, setMaxListings] = useState(5);
  const [page, setPage] = useState(1);
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    setFetching(true);
    async function anonFunc() {
      const searchResults = await ListingSvc().searchListings({ ...search, offset: 0, max: maxListings });
      setResultList(searchResults);
      setTotal(Math.ceil((await ListingSvc().getPaginationAmount({ ...search, max: 100 })) / maxListings));
      setFetching(false);
    }
    anonFunc();
  }, [search]);

  const handlePaginationChange = async (event, value) => {
    setFetching(true);
    const searchResults = await ListingSvc().searchListings({
      ...search,
      offset: (value - 1) * maxListings,
      max: maxListings,
    });
    setResultList(searchResults);
    setFetching(false);
    setPage(value);
  };

  const assignLogo = (item) => {
    let logo;
    if (item.images) {
      logo = item.images.filter((img) => img.type === 'LOGO');
      if (logo.length) {
        logo = logo[0].url;
      } else {
        logo = Placeholder;
      }
    } else {
      logo = Placeholder;
    }
    return logo;
  };

  const handleLoadError = (e) => {
    e.target.src = Placeholder;
  };

  if (fetching) {
    return <Loader Banner={Banner} />;
  }

  return (
    <Flex flexDirection="column" width={1} alignItems="center">
      <Banner />
      <Flex
        className="business-page-container"
        flexDirection="column"
        bg="white"
        mx={10}
        mt={[5, 60, null, 140]}
        sx={{ boxShadow: '0 3px 8px 0 rgba(106, 126, 150, 0.14)', borderRadius: '4px' }}
        px={[3, 6, 6, 13]}
        py={[10, null, 15]}
        maxWidth={1170}
        width={[9 / 10, null, null, 3 / 4]}
      >
        <Flex flexDirection="column" width={1}>
          <Box className="business-page-name" width={1} pb={[4]} sx={{ borderBottom: '1px solid #dbe6ed' }}>
            <Text textAlign="left" variant="heading2" as="h2">
              Search results
            </Text>
          </Box>
          <Flex flexDirection="column" overflowY="auto" height="auto">
            {resultList && resultList.length ? (
              resultList.map((item) => {
                const logo = assignLogo(item);
                return (
                  <Flex
                    flexDirection="row"
                    justifyContent="space-between"
                    px={[0, null, null, 5]}
                    pt={10}
                    width={1}
                    key={item.id}
                  >
                    <Flex flexDirection="row" alignItems="center">
                      <Link to={`/business/view/${item.slug}`} className="Logo">
                        {
                          <Image
                            src={logo}
                            id={item.id}
                            onError={handleLoadError}
                            alt=""
                            mr={[2, null, null, 12]}
                            height={[]}
                            width={[56, 85, null, 126]}
                          />
                        }
                      </Link>
                      <MainInfo item={item} />
                    </Flex>
                    {item.pin && <Map item={item} />}
                  </Flex>
                );
              })
            ) : (
              <Text variant="heading3">No Results Found</Text>
            )}
          </Flex>
          {resultList && resultList.length ? (
            <Flex flexDirection="row" justifyContent={['center', null, null, 'flex-end']} mt={5}>
              <Pagination count={total} page={page} showLastButton onChange={handlePaginationChange} size="small" />
            </Flex>
          ) : (
            ''
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SearchPage;
