import React from 'react';
import { Flex } from 'rebass/styled-components';
import { StaticGoogleMap, Marker } from 'react-static-google-map';
import StyledWrapper from './StyledMap';
const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY;

const Map = ({ item }) => {
  return (
    <StyledWrapper>
      <a
        href={`https://www.google.com/maps/@${item.pin.location.lat},${item.pin.location.lon},17z`}
        target="_blank"
        rel="nofollow"
      >
        <Flex width={[56, 85, null, 150]} height={[56, 85, null, 150]}>
          <StaticGoogleMap className="Map" size="182x163" zoom="12" apiKey={GOOGLE_MAPS_API_KEY}>
            <Marker location={`${item.pin.location.lat},${item.pin.location.lon}`} color="red" label={`${item.name}`} />
          </StaticGoogleMap>
        </Flex>
      </a>
    </StyledWrapper>
  );
};

export default Map;
