import React from 'react';
import { Location, globalHistory } from '@reach/router';
import queryString from 'query-string';
import SearchPage from '../components/organisms/SearchPage/SearchPage';
import Layout from '../layouts';

const App = () => {
  return (
    <Layout includeSearchBar>
      <Location>
        {({ location, navigate }) => (
          <SearchPage
            location={location}
            navigate={navigate}
            location={typeof window !== `undefined` ? location : null}
            search={location.search ? queryString.parse(location.search) : {}}
          />
        )}
      </Location>
    </Layout>
  );
};

export default App;
