import React from 'react';
import { Flex, Text, Box, Image } from 'rebass/styled-components';
import { Link } from 'gatsby';
import NumberFormat from 'react-number-format';
import Star from '../../../../assets/58.svg';

const MainInfo = ({ item }) => {
  return (
    <Flex flexDirection="column">
      <Link style={{ textDecoration: 'none' }} id={item.id} to={`/business/view/${item.slug}`}>
        <Text variant="searchResultHeading" as="h2">
          {item.name}
        </Text>
      </Link>
      {item.hideAddress != true && (
        <Text variant="searchResultText" as="h3">
          {item.address + (item.addressExtra ? `, ${item.addressExtra}` : '')}
        </Text>
      )}
      <Text variant="searchResultText" as="h3">{`${item.city}, ${item.state} ${item.zip}`}</Text>
      <Text variant="searchResultText" as="h3">
        {item.phones && item.phones.length ? (
          <NumberFormat value={item.phones[0].number} format="(###) ###-####" displayType={'text'} />
        ) : (
          ''
        )}
      </Text>
      <Flex>
        {item.specialOffer ? (
          <Flex flexDirection="row" flexDirection="space-around">
            <Image src={Star} id={item.id} alt="" width={[1 / 10, null, null, null]} mr={[0, null, null, 2]} />
            <Text variant="specialOffer">{item.specialOffer.title}</Text>
          </Flex>
        ) : (
          ''
        )}
      </Flex>
    </Flex>
  );
};

export default MainInfo;
